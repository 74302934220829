@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');

body {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    color: #2b2b2b;
    font-weight:300;
    line-height:25px;
    width: 100%;
    height: 100vh;
    margin: 0;
    padding: 0;
}

h1, h2, h3, h4, h5, h6
{
    line-height: 1.2;
    font-weight: 400;
    font-family: 'Oswald', sans-serif;
    text-transform: uppercase!important;
}

a, *::before, *::after, img, span, input, button
{
    transition: ease-in-out 0.2s;
    -webkit-transition: ease-in-out 0.2s;
    -moz-transition: ease-in-out 0.2s;
    -ms-transition: ease-in-out 0.2s;
    -o-transition: ease-in-out 0.2s;
}

a
{
    color:inherit;
    text-decoration: none;
}

.btn.focus, .btn:focus
{
    -webkit-box-shadow: none;
    box-shadow: none;
}

p:last-child
{
    margin-bottom: 0 !important;
}

a:active, a:hover
{
    color: #02aeb3;
}

.btn
{
    -webkit-appearance: initial;
    overflow: hidden;
    position: -webkit-sticky;
    position: sticky;
    z-index: 2;
    display: inline-block;
    font-size: 17px;
    border:2px solid transparent;
    letter-spacing: 1px;
    line-height: inherit;
    border-radius: 0;
    text-transform: capitalize;
    width: auto;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    -webkit-transition: all .5s ease;
    -o-transition: all .5s ease !important;
    transition: all .5s ease !important;
}

.search-wrapper
{
    position: relative;
    z-index: 11;
    color: #ffffff;
    padding: 50px 20px 70px 20px;
    background-color: #b24dff;
}
.search-wrapper h2
{
    font-size: 60px;
    font-family: 'Oswald', sans-serif;
    font-weight: 200;
    margin-bottom: 20px;
    text-transform: initial !important;
    letter-spacing: 10px;
    text-align: center;
}

.search-wrapper h2 .search-bold-text
{
    display: inline-block;
    font-family: 'Oswald', sans-serif;
    font-weight: 700;
    letter-spacing: 2px;
}

.search-wrapper p
{
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
}

#map
{
    width: 100%;
    height: 100vh;
    margin-top: 60px;
}

.btn-search
{
    width: calc(50% - 15px);
    color: #FFFFFF;
    padding: 10px 0;
    background-color: #2b2b2b;
    margin-top: 15px;
}

.btn-search:hover
{
    color: #dcdcdc;
}

.search-form label
{
    margin-bottom: 0;
    font-weight: 400;
    letter-spacing: 1px;
}

.view
{
    width: 100%;
    height: 100vh;
    min-height: 100%;
}

.search
{
    background-size:cover;
    background-position: center;
    background-repeat: no-repeat;
}

.results
{

}

.inner-results
{

}

.court-title
{
    display: inline-block;
    font-size: 18px;
}

.courts-title
{
    margin: 60px 0 10px 0;
}

table.court-table td
{
    text-align: center;
    padding-left: 1px !important;
    padding-right: 1px !important;
    border-right: 1px solid #ffffff;
    overflow:hidden;
    padding:0.15em;
    font-size: 12px;
}

table.court-table td.free
{
    background-color: #9dfa9d !important;
    font-weight: bold;
}

table.court-table td.occupied
{
    background-color: #d1d1d1;
    color: #6a6a6a;
}

table.court-table
{
    table-layout: fixed;
}

table.court-table tbody td.firstColumn {
    position: sticky;
    left: 0;
    background: white;
    z-index: 1;
}

.search-back-button
{
    position: absolute;
    top: 10px;
    right: 15px;
}

.react-datepicker-popper {
    z-index: 3 !important;
}

.date-pick-button
{
    width: 100%;
    background-color: #ffffff;
    border-radius: 4px;
    color: #000000;
    height: 38px;
    display: flex;
    padding-left: 10px;
    align-items: center;
}

@media (max-height: 500px)
{
    .search-wrapper { height: auto !important; }
    .results .col { padding: 0 5px; }
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px)
{
    .search-wrapper { width: 100%; height: 100vh; }
    .search-wrapper h2 { font-size: 40px; }
    .search-col { padding: 0; }
    .litepicker { top: 0 !important; left: 0 !important; }
    .litepicker .container__main { height: 100vh !important; }
    .courts-wrapper { padding-top: 70px; }
    .court-table-wrapper { overflow-x: scroll; }
    table.court-table td { width: 50px; }
    .courts-wrapper h1 { font-size: 20px; margin: 0 0 20px 0; background-color: #eaeaea; padding: 10px 5px; border-bottom: 1px solid #a5a5a5; }
    .search-back-button { width: 92%; }
    .btn-search { width: 100%; }
    .inner-results { }
    .results { }
    table.court-table tbody td.firstColumn { width: 30px;}
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px)
{
    .search-wrapper { width: 100%; height: 100vh; }
    .search-wrapper h2 { font-size: 40px; }
    .search-col { padding: 0; }
    .litepicker { top: 0 !important; left: 0 !important; }
    .litepicker .container__main { height: 100vh !important; }
    .courts-wrapper { padding-top: 70px; }
    .courts-wrapper h1 { font-size: 24px; margin: 0 0 20px 0; background-color: #eaeaea; padding: 10px 5px; border-bottom: 1px solid #a5a5a5; }
    .inner-results { margin-bottom: 100px; }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px)
{
    .search-wrapper { width: 100%; height: 100vh; }
    .search-wrapper h2 { font-size: 40px; }
    .search-col { padding: 0; }
    .litepicker { top: 0 !important; left: 0 !important; }
    .litepicker .container__main { height: 100vh !important; }
    .courts-wrapper { padding-top: 70px; }
    .courts-wrapper h1 { font-size: 24px; margin: 0 0 20px 0; background-color: #eaeaea; padding: 10px 5px; border-bottom: 1px solid #a5a5a5; }
    .inner-results { margin-bottom: 100px; }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px)
{

}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px)
{

}